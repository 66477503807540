import React from 'react'
import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import {Row, Col} from 'antd'
import {getChildrenToRender} from './utils'
import './footer.less'

class Footer extends React.Component {
    static defaultProps = {
        className: 'footer1',
    }
    state = {
        isShowPic: false,
    }
    getLiChildren = (data) =>
        data.map((item, i) => {
            const {title, childWrapper, ...itemProps} = item
            const {...props} = this.props
            let margin_l = 0
            if (i == 0) {
                margin_l = '0'
            } else if (i == 4) {
                margin_l = '1.65rem'
            } else {
                margin_l = '-2.65rem'
            }
            return (
                <Col
                    key={i.toString()}
                    {...itemProps}
                    title={null}
                    content={null}
                    style={{
                        //transition: "all 1s",
                        marginTop: '1rem',
                        marginLeft: props.isMobile ? '0' : margin_l,
                    }}
                >
                    <h2
                        {...title}
                        style={{
                            //transition: "all 1s",
                            color: '#00AA71',
                            fontWeight: 700,
                            fontSize: '1.5rem',
                        }}
                    >
                        {i == 4 ? (
                            <img
                                src={require('../assets/white_logo_bg.png')}
                                width="100%"
                                style={{
                                    marginLeft: props.isMobile ? '' : '9.6rem',
                                    // width: "20rem !important",
                                    // height: "3rem",
                                }}
                                alt="img"
                            />
                        ) : (
                            title.children
                        )}
                        {/* {typeof title.children === "string" &&
                        title.children.match(isImg) ? (
                            // <img src={title.children} width="100%" alt="img" />
                            <img
                                src={require("../assets/white_logo_bg.png")}
                                width="100%"
                                style={{ background: "#fff", padding: "10px" }}
                                alt="img"
                            />
                        ) : (
                            title.children
                        )} */}
                    </h2>
                    <div {...childWrapper}>
                        {childWrapper.children.map(getChildrenToRender)}
                    </div>
                    {i == 4 ? (
                        <div
                            className="qrcode_wrapper"
                            style={{
                                //transition: "all 1s",
                                marginLeft: props.isMobile ? '' : '15rem',
                            }}
                        >
                            <img
                                style={{
                                    width: '10.625rem',
                                    height: '10.625rem',
                                    marginTop: '1.6rem',
                                }}
                                // src={require("../assets/2.png")}
                                src={require('../assets/5.png')}
                                alt=""
                            />
                            <div
                                style={{
                                    fontSize: '1rem',
                                    marginTop: '1rem',
                                    width: '10rem',
                                    color: '#CBCBCB',
                                }}
                            >
                                扫一扫，关注超云微信
                            </div>
                        </div>
                    ) : null}
                </Col>
            )
        })

    render() {
        const {...props} = this.props
        const {dataSource} = props
        delete props.dataSource
        const childrenToRender = this.getLiChildren(dataSource.block.children)
        return (
            <div {...dataSource.wrapper}>
                <OverPack {...dataSource.OverPack}>
                    <TweenOne
                        forcedReplay={false}
                        type="bottom"
                        key="ul"
                        component={Row}
                        {...dataSource.block}
                    >
                        <div
                            style={{
                                marginLeft: props.isMobile ? '' : '-3rem',
                                maxWidth: '1920px !important',
                                position: 'relative',
                                height: props.isMobile ? 'auto' : '18rem',
                            }}
                        >
                            {!props.isMobile ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: '2rem',
                                        cursor: 'pointer',
                                    }}
                                    onMouseEnter={() => {
                                        this.setState({
                                            isShowPic: true,
                                        })
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({
                                            isShowPic: false,
                                        })
                                    }}
                                >
                                    <img
                                        src={require('../assets/wechat_icon.png')}
                                        style={{
                                            width: '1.2rem',
                                            height: '1rem',
                                        }}
                                    />
                                </div>
                            ) : null}
                            {!props.isMobile ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: '4rem',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        window.location.open(
                                            'https://weibo.com/chinasupercloud?tabtype=feed'
                                        )
                                    }}
                                >
                                    <img
                                        src={require('../assets/weibo_icon.png')}
                                        style={{
                                            width: '1.2rem',
                                            height: '1rem',
                                        }}
                                    />
                                </div>
                            ) : null}
                            {this.state.isShowPic ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '2rem',
                                        left: '2rem',
                                        cursor: 'pointer',
                                        zIndex: 10000,
                                        transition: 'all 0.5',
                                    }}
                                >
                                    <img
                                        src={require('../assets/5.png')}
                                        style={{
                                            width: '9rem',
                                            height: '9rem',
                                            transition: 'all 0.5',
                                        }}
                                    />
                                </div>
                            ) : null}

                            {childrenToRender}
                        </div>
                    </TweenOne>
                    <TweenOne
                        animation={{y: '+=30', opacity: 0, type: 'from'}}
                        key="copyright"
                        {...dataSource.copyrightWrapper}
                    >
                        {props.isMobile ? (
                            <div className="m_copyright">
                                {dataSource.copyright.children}
                            </div>
                        ) : (
                            <div {...dataSource.copyrightPage}>
                                <div {...dataSource.copyright}>
                                    {dataSource.copyright.children}
                                </div>
                            </div>
                        )}
                    </TweenOne>
                </OverPack>
            </div>
        )
    }
}

export default Footer
