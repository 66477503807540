/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import {enquireScreen} from 'enquire-js'

import CSCBackTop from '../../widget/CSCBackTop'
import {Button, Checkbox, Form, Input, Table, Divider} from 'antd'

import config from '../../libs/config'
const {TextArea} = Input
const {Search} = Input

let isMobile
enquireScreen((b) => {
    isMobile = b
})

const location = window.location

export default class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile,
            serverSerialNumberList: [
                // 120
                'NA220402097',
                'NA220402098',
                'NA220402099',
                'NA220402100',
                'NA220402101',
                'NA220402102',
                'NA220402103',
                'NA220402104',
                'NA220402105',
                'NA220402106',
                'NA221000042',
                'NA221000043',
                'NA221000044',
                'NA221000045',
                'NA221000046',
                'NA221000047',
                'NA221000048',
                'NA221000049',
                'NA221000050',
                'NA221000051',
                'NA221000052',
                'NA221000053',
                'NA221000054',
                'NA221000055',
                'NA221000056',
                'NA221000057',
                'NA221000058',
                'NA221000059',
                'NA221000060',
                'NA221000061',
                'NA221000062',
                'NA221000063',
                'NA221000064',
                'NA221000065',
                'NA221000066',
                'NA221000067',
                'NA221000068',
                'NA221000069',
                'NA221000070',
                'NA221000071',
                'NA221000155',
                'NA221000156',
                'NA221000157',
                'NA221000158',
                'NA221000159',
                'NA221000160',
                'NA221000161',
                'NA221000162',
                'NA221000163',
                'NA221000164',
                'NA221000165',
                'NA221000166',
                'NA221000167',
                'NA221000168',
                'NA221000169',
                'NA221000170',
                'NA221000171',
                'NA221000172',
                'NA221000173',
                'NA221000174',
                'NA221000250',
                'NA221000251',
                'NA221000252',
                'NA221000253',
                'NA221000254',
                'NA221000255',
                'NA221000256',
                'NA221000257',
                'NA221000258',
                'NA221000259',
                'NA221000260',
                'NA221000261',
                'NA221000262',
                'NA221000263',
                'NA221000264',
                'NA221000265',
                'NA221000266',
                'NA221000267',
                'NA221000268',
                'NA221000269',
                'NA221000377',
                'NA221000378',
                'NA221000379',
                'NA221000380',
                'NA221000381',
                'NA221000382',
                'NA221000383',
                'NA221000384',
                'NA221000385',
                'NA221000386',
                'NA221000517',
                'NA221000518',
                'NA221000519',
                'NA221000520',
                'NA221000521',
                'NA221000522',
                'NA221000523',
                'NA221000524',
                'NA221000525',
                'NA221000526',
                'NA221000527',
                'NA221000528',
                'NA221000529',
                'NA221000530',
                'NA221000531',
                'NA221000532',
                'NA221000533',
                'NA221000534',
                'NA221000535',
                'NA221000536',
                'NA221000537',
                'NA221000538',
                'NA221000539',
                'NA221000540',
                'NA221000541',
                'NA221000542',
                'NA221000543',
                'NA221000544',
                'NA221000545',
                'NA221000546',
                // 200
                'NA220402107',
                'NA220402108',
                'NA220402109',
                'NA220402110',
                'NA220402111',
                'NA220402112',
                'NA220402113',
                'NA220402114',
                'NA220402115',
                'NA220402116',
                'NA221000073',
                'NA221000074',
                'NA221000075',
                'NA221000076',
                'NA221000077',
                'NA221000078',
                'NA221000079',
                'NA221000080',
                'NA221000081',
                'NA221000082',
                'NA221000083',
                'NA221000084',
                'NA221000085',
                'NA221000086',
                'NA221000087',
                'NA221000088',
                'NA221000089',
                'NA221000090',
                'NA221000091',
                'NA221000092',
                'NA221000093',
                'NA221000094',
                'NA221000095',
                'NA221000096',
                'NA221000097',
                'NA221000098',
                'NA221000099',
                'NA221000100',
                'NA221000101',
                'NA221000102',
                'NA221000175',
                'NA221000176',
                'NA221000177',
                'NA221000178',
                'NA221000179',
                'NA221000180',
                'NA221000181',
                'NA221000182',
                'NA221000183',
                'NA221000184',
                'NA221000185',
                'NA221000186',
                'NA221000187',
                'NA221000188',
                'NA221000189',
                'NA221000190',
                'NA221000191',
                'NA221000192',
                'NA221000193',
                'NA221000194',
                'NA221000270',
                'NA221000271',
                'NA221000272',
                'NA221000273',
                'NA221000274',
                'NA221000275',
                'NA221000276',
                'NA221000277',
                'NA221000278',
                'NA221000279',
                'NA221000280',
                'NA221000281',
                'NA221000282',
                'NA221000283',
                'NA221000284',
                'NA221000285',
                'NA221000286',
                'NA221000287',
                'NA221000288',
                'NA221000289',
                'NA221000311',
                'NA221000312',
                'NA221000313',
                'NA221000314',
                'NA221000315',
                'NA221000316',
                'NA221000317',
                'NA221000318',
                'NA221000319',
                'NA221000320',
                'NA221000321',
                'NA221000322',
                'NA221000323',
                'NA221000324',
                'NA221000325',
                'NA221000326',
                'NA221000327',
                'NA221000328',
                'NA221000329',
                'NA221000330',
                'NA221000331',
                'NA221000332',
                'NA221000333',
                'NA221000334',
                'NA221000335',
                'NA221000336',
                'NA221000337',
                'NA221000338',
                'NA221000339',
                'NA221000340',
                'NA221000341',
                'NA221000342',
                'NA221000343',
                'NA221000344',
                'NA221000345',
                'NA221000346',
                'NA221000347',
                'NA221000348',
                'NA221000349',
                'NA221000350',
                'NA221000351',
                'NA221000352',
                'NA221000353',
                'NA221000354',
                'NA221000355',
                'NA221000356',
                'NA221000357',
                'NA221000358',
                'NA221000359',
                'NA221000360',
                'NA221000926',
                'NA221000927',
                'NA221000928',
                'NA221000929',
                'NA221000930',
                'NA221000931',
                'NA221000932',
                'NA221000933',
                'NA221000934',
                'NA221000935',
                'NA221000936',
                'NA221000937',
                'NA221000938',
                'NA221000939',
                'NA221000940',
                'NA221000941',
                'NA221000942',
                'NA221000943',
                'NA221000944',
                'NA221000945',
                'NA221000946',
                'NA221000947',
                'NA221000948',
                'NA221000949',
                'NA221000950',
                'NA221000951',
                'NA221000952',
                'NA221000953',
                'NA221000954',
                'NA221000955',
                'NA221100206',
                'NA221100207',
                'NA221100208',
                'NA221100209',
                'NA221100210',
                'NA221100211',
                'NA221100212',
                'NA221100213',
                'NA221100214',
                'NA221100215',
                'NA221100216',
                'NA221100217',
                'NA221100218',
                'NA221100219',
                'NA221100220',
                'NA221100221',
                'NA221101549',
                'NA221101550',
                'NA221101551',
                'NA221101552',
                'NA221101553',
                'NA221101554',
                'NA221101555',
                'NA221101556',
                'NA221101557',
                'NA221101558',
                'NA221101559',
                'NA221101560',
                'NA221101561',
                'NA221101562',
                'NA221101563',
                'NA221101564',
                'NA221101565',
                'NA221101566',
                'NA221101567',
                'NA221101568',
                'NA221101569',
                'NA221101570',
                'NA221101571',
                'NA221101572',
                // 1
                // NA230802002
            ],
            ssnl_2: [
                'NA220401914',
                'NA220401915',
                'NA220401916',
                'NA220401917',
                'NA220401918',
                'NA220401919',
                'NA220401920',
                'NA220401921',
                'NA220401922',
                'NA220401923',
                'NA220401924',
                'NA220401925',
                'NA220401926',
                'NA220401927',
                'NA220401928',
                'NA220401929',
                'NA220401930',
                'NA220401931',
                'NA220401932',
                'NA220401933',
                'NA220401934',
                'NA220401935',
                'NA220401936',
                'NA220401937',
                'NA220401938',
                'NA220401939',
                'NA220401940',
                'NA220401941',
                'NA220401942',
                'NA220401943',
                'NA220401944',
                'NA220401945',
                'NA220401946',
                'NA220401947',
                'NA220401948',
                'NA220401949',
                'NA220401950',
                'NA220401951',
                'NA220401952',
                'NA220401953',
                'NA220401954',
                'NA220401955',
                'NA220401956',
                'NA220401957',
                'NA220401958',
                'NA220401959',
                'NA220401960',
                'NA220401961',
                'NA220401962',
                'NA220401963',
                'NA220603944',
                'NA220603945',
                'NA220603946',
                'NA220603947',
                'NA220603948',
                'NA220603949',
                'NA220603950',
                'NA220603951',
                'NA220603952',
                'NA220603953',
                'NA220603954',
                'NA220603955',
                'NA220603956',
                'NA220603957',
                'NA220603958',
                'NA220603959',
                'NA220603960',
                'NA220603961',
                'NA220603962',
                'NA220603963',
                'NA220603964',
                'NA220603965',
                'NA220603966',
                'NA220603967',
                'NA220603968',
                'NA220603969',
                'NA220603970',
                'NA220603971',
                'NA220603972',
                'NA220603973',
                'NA220603974',
                'NA220603975',
                'NA220603976',
                'NA220603977',
                'NA220603978',
                'NA220603979',
                'NA220603980',
                'NA220603981',
                'NA220603982',
                'NA220603983',
                'NA220603984',
                'NA220603985',
                'NA220603986',
                'NA220603987',
                'NA220603988',
                'NA220603989',
                'NA220603990',
                'NA220603991',
                'NA220603992',
                'NA220603993',
            ],
            isShowTable: false,
            ieus: [
                '100NA220021',
                '100NA220022',
                '100NA220023',
                '100NA220024',
                '100NA220025',
                '100NA220026',
                '100NA220027',
                '100NA220028',
                '100NA220029',
            ],
            sdkryq: [
                '100NA270012',
                '100NA270013',
                '100NA270014',
                '100NA270015',
                '100NA270016',
            ],
            hnspkj: [
                '100NA230059',
                '100NA230060',
                '100NA230061',
                '100NA230062',
                '100NA230063',
                '100NA230064',
                '100NA230065',
                '100NA230066',
                '100NA230067',
                '100NA230068',
                '100NA230069',
                '100NA230070',
            ],
            kanhaiwangluo_1: [
                '100NA271510',
                '100NA271511',
                '100NA271512',
                '100NA271513',
                '100NA271514',
                '100NA271515',
            ],
            kanhaiwangluo_2: ['100NA271509', '100NA271508'],
            // 大连商品交易所
            dalianNANumber: ['100NA260836'],
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            productSerialNumber: '',
            infoForm: {},
            serverSerialNumber: '',
            isAddSearch: false,
            cacheInfoForm: {
                customerName: '海看网络科技（山东）股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2023年09月10日', // 出厂日期
                serverModel: 'R8428', //服务器型号
                expirationDate: '2029年9月8日', //保修截止日期
                onSiteServiceEndDate: '2029年9月8日', // 现场服务截止日期
                a800EndDate: '2026年09月10日', // A800保修截止日期
                serviceContent: `1.保修服务流程 超云面向全国客户提供7x24小时的服务热线支持，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。`,
            },
            cacheInfoForm_2: {
                customerName: '中国人寿保险股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2022年09月28日', // 出厂日期
                serverModel: 'R3216', //服务器型号
                expirationDate: '2028年08月28日', //保修截止日期
                onSiteServiceEndDate: '2028年08月28日', // 现场服务截止日期

                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.保修服务流程 超云面向全国客户提供7x24小时的服务热线支持，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。`,
            },
            cacheInfoForm_3: {
                customerName: '海看网络科技（山东）股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年01月09日', // 出厂日期
                serverModel: 'R5215 G12', //服务器型号
                expirationDate: '2027年01月08日', //保修截止日期
                onSiteServiceEndDate: '2027年01月08日', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.保修服务流程 7*24小时电话支持与上门服务，NBD服务，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。`,
            },
            cacheInfoForm_4: {
                customerName: '中国人寿保险股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2022年07月06日', // 出厂日期
                serverModel: 'R3216', //服务器型号
                expirationDate: '2028年12月04日', //保修截止日期
                onSiteServiceEndDate: '2028年12月04日', // 现场服务截止日期
                softServiceEndDate: '2028年12月04日', // 软件服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.保修服务流程 超云面向全国客户提供7x24小时的服务热线支持，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。\n3.含CS13000存储软件产品。`,
            },
            cacheInfoForm_5: {
                customerName: '海看网络科技(山东)股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年03月11日', // 出厂日期
                serverModel: 'R5215 G12', //服务器型号
                expirationDate: '2029年03月10日', //保修截止日期
                onSiteServiceEndDate: '2029年03月10日', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.保修服务流程 7*24小时电话支持与上门服务，NBD服务，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。`,
            },
            cacheInfoForm_6: {
                customerName: '山东科瑞油气装备有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年07月08日', // 出厂日期
                serverModel: 'R5215 G12', //服务器型号
                expirationDate: '2027年07月08日', //保修截止日期
                onSiteServiceEndDate: '2027年07月08日', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.保修服务流程 7*24小时电话支持与上门服务，NBD服务，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。\n2.保修响应时间 超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。`,
            },
            cacheInfoForm_7: {
                customerName: '财信证劵股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年07月16日', // 出厂日期
                serverModel: 'R3216', //服务器型号
                expirationDate: '2029年07月16日', //保修截止日期
                onSiteServiceEndDate: '2029年07月16日', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `1.自主可控产品硬盘免回收服务。自主可控产品5年硬盘免回收服务。\n2.自主可控产品整机延保服务。自主可控产品5年保修 7*24小时电话支持服务下一工作日上门或在途。`,
            },
            cacheInfoForm_8: {
                customerName: '海看网络科技（山东）股份有限公司', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年08月05日', // 出厂日期
                serverModel: 'R5210 G11', //服务器型号
                expirationDate: '2030年08月05日', //保修截止日期
                onSiteServiceEndDate: '2030年08月05日', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `6年7×24×4保修服务，含电话支持服务、远程服务、上门服务。`,
            },
            cacheInfoForm_10: {
                customerName: '大连商品交易所', // 客户名称
                statusName: '在保', // 保修状态
                // serverSerialNumber: "NA230802002", // 服务器序列号
                exfactoryDate: '2024年08月06日', // 出厂日期
                serverModel: 'R3216', //服务器型号
                expirationDate: '2029年08月22日', //保修截止日期
                onSiteServiceEndDate: '具体情况与销售经理协商', // 现场服务截止日期
                a800EndDate: '', // A800保修截止日期
                serviceContent: `自主可控产品5年保修 7*24小时电话支持服务 下一工作日上门或在途；自主可控产品5年硬盘免回收服务。`,
            },
            cacheDataSource: [
                {
                    key: '1',
                    partType: 'CPU',
                    partCode: 'CPUINTP83800040230',
                    partName: 'Intel Xeon Platinum 8380 40C 2.30GHz 60MB 270W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: '内存',
                    partCode: 'FTCMEM032GDDR4ERRD',
                    partName: '32GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 8,
                },
                {
                    key: '3',
                    partType: '2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 1.92T SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 4,
                },
                {
                    key: '4',
                    partType: '网卡',
                    partCode: 'FTCNIC0010GSFP02LP',
                    partName: '10G 双光口 82599 半高网卡 无模块',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: '网卡模块',
                    partCode: 'MODTRT010GSFPMNIC2',
                    partName: 'SFP+ 万兆多模光纤模块',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '6',
                    partType: 'RAID卡',
                    partCode: 'RADLSI0007',
                    partName: '12Gb 3908 8i Raid0 1 5 6 10 50 60 4GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '7',
                    partType: '控制器组件',
                    partCode: 'CBLXXX090N12F1F2C1',
                    partName: 'Slimline x8 to Slimline x4*2 线缆',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: '服务器整机延保服务',
                    partCode: 'FTCXXXSFW000000001',
                    partName: '服务器6年保修 7*24小时电话支持服务及上门服务',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: 'A26 GPU/Phi卡',
                    partCode: 'GPUNVA0005',
                    partName: 'Tesla A800 80GB PCIe',
                    recomandType: '常规',
                    quatity: 6,
                },
                {
                    key: '10',
                    partType: 'GPU保修服务',
                    partCode: 'FTCXXXSFW000000001',
                    partName: 'GPU3年保修服务 7*24小时电话支持服务及上门服务',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_3: [
                {
                    key: '1',
                    partType: 'CPU',
                    partCode: 'CPUINTG63420024280',
                    partName: 'Intel Xeon Gold 6342 24C 2.80GHz 36MB 230W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: '内存',
                    partCode: 'FTCMEM032GDDR4ERRD',
                    partName: '32GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 8,
                },
                {
                    key: '3',
                    partType: '盘位类型',
                    partCode: 'VIRSYS0021',
                    partName:
                        '2U32DIMM平台，含前置12 盘直通背板（8SAS/SATA+4SAS/SATA/NVMe ）',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '4',
                    partType: '平台散热套件',
                    partCode: 'VIRSNK0024',
                    partName: 'GPU导风罩+GPU 散热片',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: '2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 1.92T SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 4,
                },
                {
                    key: '6',
                    partType: '硬盘支架',
                    partCode: 'MCPHQN0001',
                    partName: '3.5转2.5硬盘托架(R5215 G12)',
                    recomandType: '常规',
                    quatity: 4,
                },

                {
                    key: '7',
                    partType: '控制器组件',
                    partCode: 'VIRCBL0017',
                    partName:
                        '前置12盘SATA/SAS线缆（搭配12盘直连背板）-搭配95系列RAID/SAS卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: '网卡',
                    partCode: 'FTCNIC0010GSFP02LP',
                    partName: '10G 双光口 82599 半高网卡 无模块',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: '网卡模块',
                    partCode: 'MODTRT010GSFPMNIC2',
                    partName: 'SFP+ 万兆多模光纤模块',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '10',
                    partType: 'RAID卡',
                    partCode: 'RADLSI0007',
                    partName: '12Gb 3908 8i Raid0 1 5 6 10 50 60 4GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '11',
                    partType: 'GPU套件',
                    partCode: 'CBLXXXGPUPWS8P8P03',
                    partName: '8PIN转2个8(6+2)GPU电源转接线',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '12',
                    partType: '转接卡',
                    partCode: 'RSCHQN0005',
                    partName: 'Riser1&2  X16(DW,FHFL) + X16(FHHL)',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '13',
                    partType: '转接卡',
                    partCode: 'RSCHQN0009',
                    partName: 'Riser3 X8(FHHL)+X8(FHHL)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '14',
                    partType: '电源',
                    partCode: 'VIRPWS0008',
                    partName: '2U 1600W AC 白金电源(冗电源)',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_5: [
                {
                    key: '1',
                    partType: 'CPU',
                    partCode: 'CPUINTS43100012210',
                    partName: 'Intel Xeon Silver 4310 12C 2.10GHz 18MB 120W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: '内存',
                    partCode: 'FTCMEM032GDDR4ERRD',
                    partName: '32GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 4,
                },
                {
                    key: '3',
                    partType: '盘位类型',
                    partCode: 'VIRSYS0022',
                    partName:
                        '2U32DIMM平台，含前置12 盘扩展背板（12 SAS/SATA）',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '4',
                    partType: '平台散热套件',
                    partCode: 'VIRSNK0023',
                    partName: '标准散热器+导风罩',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: '3.5寸 SATA硬盘',
                    partCode: 'FTCHDDSATA00000001',
                    partName: '3.5" 4T 7200RPM SATA HDD',
                    recomandType: '常规',
                    quatity: 10,
                },
                {
                    key: '6',
                    partType: '2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 960G SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 2,
                },

                {
                    key: '7',
                    partType: '硬盘支架',
                    partCode: 'MCPHQN0001',
                    partName: '3.5转2.5硬盘托架(R5215 G12)',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '8',
                    partType: '控制器组件',
                    partCode: 'VIRCBL0026',
                    partName:
                        '前置12盘位SAS/SATA线缆-适配12盘扩展背板-搭配3108RAID卡/3008SAS卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: '网卡',
                    partCode: 'FTCNIC1000MRJ452LP',
                    partName: '1G 双电口 I350 半高网卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: 'RAID卡',
                    partCode: 'FTCRAD080L12310801',
                    partName: '12Gb 3108 8i Raid0 1 5 6 10 50 60 2GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '11',
                    partType: '转接卡',
                    partCode: 'RSCHQN0010',
                    partName: 'Riser1&amp;2 X16(FHFL)+X8(FHHL)+X8(FHHL)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '12',
                    partType: '转接卡',
                    partCode: 'RSCHQN0005',
                    partName: 'Riser1&2  X16(DW,FHFL) + X16(FHHL)',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '13',
                    partType: '电源',
                    partCode: 'VIRPWS2U1200WACR01',
                    partName: '1200W AC 白金电源（冗电源）',
                    recomandType: '常规',
                    quatity: 1,
                },
                //  {
                //     key: '14',
                //     partType: '电源',
                //     partCode: "VIRPWS0008",
                //     partName: '2U 1600W AC 白金电源(冗电源)',
                //     recomandType: "常规",
                //     quatity: 1,
                // },
            ],
            cacheDataSource_6: [
                {
                    key: '10',
                    partType: '产品描述',
                    partCode:
                        '机架式2U服务器，支持两颗第三代英特尔 至强 可扩展系列处理器，板载Intel C621A芯片组，最大支持32根内存插槽，平台支持前置12个3.5寸热插拔SAS/SATA/SSD硬盘位可选SAS/RAID卡支持不同硬盘模式，板载Intel i210双千兆网口，独立IPMI管理接口，最多支持8个PCIe槽，可选800W/1200W/1600W/2400W 1+1白金冗余电源，无光驱，含导轨（详细信息需参考产品白皮书）',
                    partName: 'IC526',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '1',
                    partType: 'CPU',
                    partCode: 'CPUINTS43140016240',
                    partName: 'Intel Xeon Silver 4314 16C 2.40GHz 24MB 135W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: '内存',
                    partCode: 'FTCMEM064GDDR4ERRD',
                    partName: '64GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 8,
                },
                {
                    key: '3',
                    partType: '盘位类型',
                    partCode: 'VIRSYS0043',
                    partName:
                        '2U32DIMM平台，含前置12 盘直通背板（8SAS/SATA+4SAS/SATA/NVMe ',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '4',
                    partType: '平台散热套件',
                    partCode: 'VIRSNK0023',
                    partName: '标准散热器+导风罩',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: '2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 1.92T SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 7,
                },
                {
                    key: '6',
                    partType: '硬盘支架',
                    partCode: 'MCPHQN0001',
                    partName: '3.5转2.5硬盘托架(R5215 G12)',
                    recomandType: '常规',
                    quatity: 7,
                },

                // {
                //     key: '7',
                //     partType: '硬盘支架',
                //     partCode: "MCPHQN0001",
                //     partName: '3.5转2.5硬盘托架(R5215 G12)',
                //     recomandType: "常规",
                //     quatity: 2,
                // },
                {
                    key: '7',
                    partType: '控制器组件',
                    partCode: 'VIRCBL0025',
                    partName:
                        '前置12盘位SAS/SATA线缆-适配12盘直通背板-搭配3108RAID/3008SAS卡/板载SATA控制器',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: '网卡',
                    partCode: 'FTCNIC1000MRJ452LP',
                    partName: '1G 双电口 I350 半高网卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: 'RAID卡',
                    partCode: 'FTCRAD080L12310801',
                    partName: '12Gb 3108 8i Raid0 1 5 6 10 50 60 2GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: 'RAID卡电池',
                    partCode: 'VIRLSIBTR310800001',
                    partName: '电池:2G缓存 3108芯片 RAID卡电池超级电容',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '12',
                    partType: '转接卡',
                    partCode: 'RSCHQN0010',
                    partName: 'Riser1&amp;2 X16(FHFL)+X8(FHHL)+X8(FHHL)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '13',
                    partType: '电源',
                    partCode: 'VIRPWS0027',
                    partName: '2U 800W AC 白金电源(冗电源)',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_7: [
                {
                    key: '1',
                    partType: '服务',
                    partCode: '自主可控产品硬盘免回收服务',
                    partName: '自主可控产品5年硬盘免回收服务',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '2',
                    partType: '服务',
                    partCode: '自主可控产品整机延保服务',
                    partName:
                        '自主可控产品5年保修 7*24小时电话支持服务下一工作日上门或在途',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_8: [
                {
                    key: '1',
                    partType: 'A01 CPU',
                    partCode: 'CPUINTS42100010220',
                    partName: 'Intel Xeon Silver 4210 10C 2.20GHz 13.75MB 85W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: 'A02 内存',
                    partCode: 'FTCMEM032GDDR4ERRD',
                    partName: '32GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '3',
                    partType: 'A04 2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 960G SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '4',
                    partType: 'A11 网卡',
                    partCode: 'NICMEX0010G2FCLP04',
                    partName: '10G 双光口 半高网卡 无模块',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: 'A12 网卡模块',
                    partCode: 'MODTRT010GSFPMNIC2',
                    partName: 'SFP+ 万兆多模光纤模块',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '6',
                    partType: 'A17 RAID卡',
                    partCode: 'FTCRAD080L12310801',
                    partName: '12Gb 3108 8i Raid0 1 5 6 10 50 60 2GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '7',
                    partType: 'A23 电源',
                    partCode: 'VIRPWS2U0550WACR14',
                    partName: '550W AC 白金电源(冗电源)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: 'A51 盘位类型',
                    partCode: 'VIRCSE0066',
                    partName: 'R5210 2U 3.5寸8盘位类型（125W以上TDP选配',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: 'A52 控制器类型',
                    partCode: 'MBDSMCINTDPX11DPI1',
                    partName:
                        '英特尔® 至强® 可扩展处理器 双路6*PCI-E（4*PCI-E x16+2*PCI-E x8）插槽',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: 'A53 控制器组件',
                    partCode: 'VIRCBL0058',
                    partName:
                        '前置8盘位SAS/SATA线缆-适配8盘位背板搭配3108/3008 8i卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: '服务',
                    partCode: 'FTCXXXSFW000000001',
                    partName:
                        '服务器6年7×24×4质保服务，含电话支持服务、远程服务、上门服务。',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_9: [
                {
                    key: '1',
                    partType: 'A01 CPU',
                    partCode: 'CPUINTS42100010220',
                    partName: 'Intel Xeon Silver 4210 10C 2.20GHz 13.75MB 85W',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: 'A02 内存',
                    partCode: 'FTCMEM032GDDR4ERRD',
                    partName: '32GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 4,
                },
                {
                    key: '3',
                    partType: 'A04 2.5寸 SSD硬盘',
                    partCode: 'FTCHDDSSD000000001',
                    partName: '2.5" 1.92T SATA 读取密集 SSD',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '32',
                    partType: 'A08 3.5寸 SATA硬盘',
                    partCode: 'FTCHDDSATA00000001',
                    partName: '3.5" 4T 7200RPM SATA HDD',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '4',
                    partType: 'A11 网卡',
                    partCode: 'NICMEX0010G2FCLP04',
                    partName: '10G 双光口 半高网卡 无模块',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: 'A12 网卡模块',
                    partCode: 'MODTRT010GSFPMNIC2',
                    partName: 'SFP+ 万兆多模光纤模块',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '6',
                    partType: 'A17 RAID卡',
                    partCode: 'FTCRAD080L12310801',
                    partName: '12Gb 3108 8i Raid0 1 5 6 10 50 60 2GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '7',
                    partType: 'A23 电源',
                    partCode: 'VIRPWS2U0550WACR14',
                    partName: '550W AC 白金电源(冗电源)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: 'A51 盘位类型',
                    partCode: 'VIRCSE0066',
                    partName: 'R5210 2U 3.5寸8盘位类型（125W以上TDP选配',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: 'A52 控制器类型',
                    partCode: 'MBDSMCINTDPX11DPI1',
                    partName:
                        '英特尔® 至强® 可扩展处理器 双路6*PCI-E（4*PCI-E x16+2*PCI-E x8）插槽',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: 'A53 控制器组件',
                    partCode: 'VIRCBL0058',
                    partName:
                        '前置8盘位SAS/SATA线缆-适配8盘位背板搭配3108/3008 8i卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '11',
                    partType: '服务',
                    partCode: 'FTCXXXSFW000000001',
                    partName:
                        '服务器6年7×24×4质保服务，含电话支持服务、远程服务、上门服务。',
                    recomandType: '常规',
                    quatity: 1,
                },
            ],
            cacheDataSource_10: [
                {
                    key: '1',
                    partType: 'CPU',
                    partCode: 'VIRCPU0052',
                    partName: 'C86-3G 7380 (32C,2.2G,220W)，含L型被动散热器',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '2',
                    partType: '内存条',
                    partCode: 'FTCMEM064GDDR4ERRD',
                    partName: '64GB DDR4-3200 ECC REG RDIMM',
                    recomandType: '常规',
                    quatity: 32,
                },
                {
                    key: '3',
                    partType: '2.5寸 SAS SSD',
                    partCode: 'SSDUMY0007',
                    partName: '2.5" 1.6T SAS 读写密集 SSD',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '32',
                    partType: '2.5寸 SAS SSD',
                    partCode: 'SSDUMY0008',
                    partName: '2.5" 3.2T SAS 读写密集 SSD',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '4',
                    partType: '网卡',
                    partCode: 'NICSED0001',
                    partName: '1G 四电口 I350 半高网卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '5',
                    partType: '网卡',
                    partCode: 'NICMEX0025G2FCLP02',
                    partName: '25G 双光口 MCX512A-ACAT 半高网卡 无模块',
                    recomandType: '常规',
                    quatity: 2,
                },
                {
                    key: '6',
                    partType: '网卡模块',
                    partCode: 'MODMEX025GSFPMNIC2',
                    partName:
                        'Mellanox transceiver, 25GbE, SFP28, LC-LC, 850nm, SR, up to 100m, single package',
                    recomandType: '常规',
                    quatity: 4,
                },
                {
                    key: '7',
                    partType: 'RAID卡',
                    partCode: 'FTCRAD080L12310801',
                    partName: '12Gb 3108 8i Raid0 1 5 6 10 50 60 2GB缓存 半高',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '8',
                    partType: 'RAID卡超级电容',
                    partCode: 'BTRLSI3108CVM02001',
                    partName: '2G缓存 3108芯片 RAID卡电池超级电容',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '9',
                    partType: '控制器组件',
                    partCode: 'VIRCBL0023',
                    partName:
                        '前置8盘/24盘SAS/SATA线缆-适配8盘直通背板，搭配3108/3008 8i卡',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '10',
                    partType: '电源',
                    partCode: 'VIRPWS0036',
                    partName: '800W AC 白金电源(冗电源)',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '11',
                    partType: '机箱套件',
                    partCode: 'VIRCHB0002',
                    partName:
                        '2U 24盘EXP机箱，含前置24盘扩展背板（12 SATA/SAS）',
                    recomandType: '常规',
                    quatity: 1,
                },
                {
                    key: '12',
                    partType: 'Riser卡',
                    partCode: 'RSCCSC0001',
                    partName: 'H01/F02+机箱 PCIe转3个x8 Riser卡 （Riser A/B)',
                    recomandType: '常规',
                    quatity: 2,
                },
            ],
            dataSource: [],
            columns: [],
            cacheColumns: [
                {
                    title: '配件类型',
                    dataIndex: 'partType',
                    key: 'partType',
                    width: 160,
                },
                {
                    title: '物料编码',
                    dataIndex: 'partCode',
                    key: 'partCode',
                },

                {
                    title: '配件规格',
                    dataIndex: 'partName',
                    key: 'partName',
                },
                // {
                //     title: '推荐类型',
                //     dataIndex: 'recomandType',
                //     key: 'recomandType',
                // },
                {
                    title: '数量',
                    dataIndex: 'quatity',
                    key: 'quatity',
                    width: 60,
                },
            ],
        }
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b})
        })
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                })
            }, 500)
        }
        // this.getDrivenList();
        /* 如果不是 dva 2.0 请删除 end */
    }

    onClickSearch() {
        console.log('onClickSearch', this.state.productSerialNumber)
        if (this.state.productSerialNumber) {
            if (this.state.productSerialNumber === 'NA230802002') {
                // console.log('this.state.cacheColumns',this.state.cacheColumns)
                this.setState({
                    columns: this.state.cacheColumns,
                    infoForm: this.state.cacheInfoForm,
                    dataSource: this.state.cacheDataSource,
                    serverSerialNumber: this.state.productSerialNumber,
                    isAddSearch: true,
                })
                // SN：100NA1C1049 | SN：100NA1C1050
            } else if (
                this.state.productSerialNumber === '100NA1C1049' ||
                this.state.productSerialNumber === '100NA1C1050'
            ) {
                this.setState({
                    columns: this.state.cacheColumns,
                    infoForm: this.state.cacheInfoForm_3,
                    dataSource: this.state.cacheDataSource_3,
                    serverSerialNumber: this.state.productSerialNumber,
                    isAddSearch: true,
                })
            } else {
                if (
                    this.state.serverSerialNumberList.indexOf(
                        this.state.productSerialNumber
                    ) != -1
                ) {
                    console.log(
                        'this.state.cacheColumns',
                        this.state.cacheColumns
                    )
                    this.setState({
                        // columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_2,
                        serverSerialNumber: this.state.productSerialNumber,
                        // dataSource: this.state.cacheDataSource,
                    })
                }

                if (
                    this.state.ssnl_2.indexOf(this.state.productSerialNumber) !=
                    -1
                ) {
                    this.setState({
                        // columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_4,
                        serverSerialNumber: this.state.productSerialNumber,
                        // dataSource: this.state.cacheDataSource,
                    })
                }

                if (
                    this.state.ieus.indexOf(this.state.productSerialNumber) !==
                    -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_5,
                        serverSerialNumber: this.state.productSerialNumber,
                        dataSource: this.state.cacheDataSource_5,
                        isShowTable: true,
                        // isAddSearch: true
                    })
                }

                if (
                    this.state.sdkryq.indexOf(
                        this.state.productSerialNumber
                    ) !== -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_6,
                        serverSerialNumber: this.state.productSerialNumber,
                        dataSource: this.state.cacheDataSource_6,
                        isShowTable: true,
                        // isAddSearch: true
                    })
                }

                if (
                    this.state.hnspkj.indexOf(
                        this.state.productSerialNumber
                    ) !== -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_7,
                        serverSerialNumber: this.state.productSerialNumber,
                        // dataSource: this.state.cacheDataSource_7,
                        isShowTable: false,
                        // isAddSearch: true
                    })
                }
                if (
                    this.state.kanhaiwangluo_1.indexOf(
                        this.state.productSerialNumber
                    ) !== -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_8,
                        serverSerialNumber: this.state.productSerialNumber,
                        dataSource: this.state.cacheDataSource_8,
                        isShowTable: true,
                        // isAddSearch: true
                    })
                }
                if (
                    this.state.kanhaiwangluo_2.indexOf(
                        this.state.productSerialNumber
                    ) !== -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_8,
                        serverSerialNumber: this.state.productSerialNumber,
                        dataSource: this.state.cacheDataSource_9,
                        isShowTable: true,
                        // isAddSearch: true
                    })
                }
                if (
                    this.state.dalianNANumber.indexOf(
                        this.state.productSerialNumber
                    ) !== -1
                ) {
                    this.setState({
                        columns: this.state.cacheColumns,
                        infoForm: this.state.cacheInfoForm_10,
                        serverSerialNumber: this.state.productSerialNumber,
                        dataSource: this.state.cacheDataSource_10,
                        isShowTable: true,
                        // isAddSearch: true
                    })
                }
            }
        } else {
            this.resetState()
        }
    }

    resetState() {
        this.setState({
            infoForm: {
                customerName: '', // 客户名称
                statusName: '', // 保修状态
                serverSerialNumber: '', // 服务器序列号
                exfactoryDate: '', // 出厂日期
                serverModel: '', //服务器型号
                expirationDate: '', //保修截止日期
                onSiteServiceEndDate: '', // 现场服务截止日期
                softServiceEndDate: '',
                a800EndDate: '', // A800保修截止日期
                serviceContent: '',
            },
            serverSerialNumber: '',
            dataSource: [],
        })
    }

    onClickDownload(url) {
        if (url) {
            // console.log(config.pic_url + url);
            window.open(config.pic_url + url)
        }
    }

    render() {
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {/* {this.state.show && children} */}
                {/* 自助查询 */}
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '5%',
                        // textAlign: "center",
                        display: 'flex',
                        // justifyContent: "center",
                        alignItems: 'center',
                        flexDirection: 'column',
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: '100px',
                        fontsize: '30px',
                        color: '#777',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            // textAlign: "center",
                            display: 'flex',
                            // justifyContent: "center",
                            alignItems: 'center',
                            flexDirection: 'row',
                            fontSize: '16px',
                            fontWeight: '600',
                            fontsize: '30px',
                            color: '#777',
                        }}
                    >
                        <div style={{marginRight: '8px', width: '110px'}}>
                            产品序列号:
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '1200px',
                            }}
                        >
                            <Input
                                width={1200}
                                placeholder="请输入 产品序列号"
                                size="large"
                                defaultValue={this.state.productSerialNumber}
                                onChange={(val) => {
                                    console.log('val', val)
                                    this.setState({
                                        productSerialNumber: val.target.value,
                                    })
                                    this.resetState()
                                }}
                                style={{width: 200}}
                            />
                            <Button
                                type="primary"
                                size={'large'}
                                style={{marginLeft: '20px'}}
                                onClick={() => {
                                    this.onClickSearch()
                                }}
                            >
                                搜索
                            </Button>
                        </div>
                        <div></div>
                    </div>

                    <div
                        style={{
                            width: '100%',
                            // textAlign: "center",
                            display: 'flex',
                            // justifyContent: "center",
                            flexDirection: 'column',
                            fontSize: '16px',
                            fontWeight: '600',
                            marginTop: '1.5rem',
                        }}
                    >
                        <h3
                            style={{
                                marginBottom: '15px',
                                borderBottom: '1px solid #999',
                                width: '80%',
                                paddingBottom: '10px',
                            }}
                        >
                            如有疑问请拨打4006-330-360反馈给客户服务中心进行咨询
                        </h3>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '30px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    客户名称:
                                </div>
                                <div>{this.state.infoForm.customerName}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 客户名称"
                                    size="large"
                                    value={this.state.infoForm.customerName}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    保修状态:
                                </div>
                                <div>{this.state.infoForm.statusName}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 保修状态"
                                    size="large"
                                    value={this.state.infoForm.statusName}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '30px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    服务器序号:
                                </div>
                                <div>{this.state.serverSerialNumber}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 服务器序号"
                                    size="large"
                                    value={this.state.infoForm.serverSerialNumber}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    出厂日期:
                                </div>
                                <div>{this.state.infoForm.exfactoryDate}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 出厂日期"
                                    size="large"
                                    value={this.state.infoForm.exfactoryDate}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '30px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    保修截止日期:
                                </div>
                                <div>{this.state.infoForm.expirationDate}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 保修截止日期"
                                    size="large"
                                      value={this.state.infoForm.expirationDate}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    服务器型号:
                                </div>
                                <div>{this.state.infoForm.serverModel}</div>
                                {/* <Input
                                    width={1200}
                                    placeholder="请输入 服务器型号"
                                    size="large"
                                    value={this.state.infoForm.serverModel}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '30px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '1200px',
                                }}
                            >
                                <div
                                    style={{marginRight: '8px', width: '110px'}}
                                >
                                    <div>现场服务</div>
                                    <div>截止日期:</div>
                                </div>
                                <div>
                                    {this.state.infoForm.onSiteServiceEndDate}
                                </div>
                            </div>
                            {this.state.infoForm.softServiceEndDate ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '1200px',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: '8px',
                                            width: '110px',
                                        }}
                                    >
                                        <div>软件服务</div>
                                        <div>截止日期:</div>
                                    </div>
                                    <div>
                                        {this.state.infoForm.softServiceEndDate}
                                    </div>
                                    {/* <Input
                                    width={1200}
                                    placeholder="请输入 现场服务截止日期"
                                    size="large"
                                    value={this.state.infoForm.onSiteServiceEndDate}
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                                </div>
                            ) : null}

                            {this.state.productSerialNumber === 'NA230802002' &&
                            this.state.isAddSearch ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '1200px',
                                        opacity: 1,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: '8px',
                                            width: '110px',
                                        }}
                                    >
                                        <div>A800GPU卡</div>
                                        <div>保修截止日期:</div>
                                    </div>
                                    <div>{this.state.infoForm.a800EndDate}</div>
                                    {/* <Input
                                    width={1200}
                                    placeholder="请输入 A800保修截止日期"
                                    value={this.state.infoForm.a800EndDate}
                                    
                                    size="large"
                                    onSearch={value => console.log(value)}
                                    style={{ width: 200 }}
                                /> */}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            // textAlign: "center",
                            display: 'flex',
                            // justifyContent: "center",
                            flexDirection: 'column',
                            fontSize: '16px',
                            fontWeight: '600',
                            // marginTop: "1.5rem"
                        }}
                    >
                        <h3
                            style={{
                                marginBottom: '15px',
                                borderBottom: '1px solid #999',
                                width: '80%',
                                paddingBottom: '10px',
                            }}
                        >
                            服务内容
                        </h3>
                        <div
                            style={{
                                width: '80%',
                                marginBottom: '2rem',
                                lineHeight: '28px',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {this.state.infoForm.serviceContent}
                        </div>
                    </div>
                    {((this.state.productSerialNumber === 'NA230802002' ||
                        this.state.productSerialNumber === '100NA1C1049' ||
                        this.state.productSerialNumber === '100NA1C1050') &&
                        this.state.isAddSearch) ||
                    this.state.isShowTable ? (
                        <div
                            style={{
                                width: '100%',
                                // textAlign: "center",
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                fontSize: '16px',
                                fontWeight: '600',
                                marginBottom: '3rem',
                            }}
                        >
                            <h3
                                style={{
                                    marginBottom: '15px',
                                    borderBottom: '1px solid #999',
                                    width: '80%',
                                    paddingBottom: '10px',
                                }}
                            >
                                产品配置信息
                            </h3>
                            <div style={{width: '80%'}}>
                                <Table
                                    pagination={false}
                                    style={{width: '100%'}}
                                    dataSource={this.state.dataSource}
                                    columns={this.state.columns}
                                />
                            </div>
                        </div>
                    ) : null}

                    {/* <Divider >如有疑问请拨打400反馈给客户进行咨询</Divider> */}
                </div>
                <CSCBackTop isMobile={this.state.isMobile} key="driven_down" />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        )
    }
}
